import React, { useState } from 'react';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import Lightbox from 'react-image-lightbox';
import '../styles/react-image-lightbox.css';

const GalleryComponent = ({ pics }) => {
  const images = pics.map((picture) => getSrc(picture.gatsbyImageData));
  const imagesThumb = pics.map((picture) => picture.gatsbyImageData);

  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const prevIndex = (index + images.length - 1) % images.length;
  const nextIndex = (index + images.length + 1) % images.length;

  return (
    <div>
      <div className='flex flex-wrap gap-2 justify-between items-center'>
        {imagesThumb.map((img, imgIndex) => {
          const thumbImage = getImage(img);
          if (!thumbImage) {
            return null;
          }
          return (
            <div
              key={imgIndex}
              className='rounded-lg'
              onClick={() => {
                setIsOpen(true);
                setIndex(imgIndex);
              }}
            >
              <GatsbyImage
                image={thumbImage}
                className='cursor-pointer w-28 h-28 md:h-36 md:w-36  hover:opacity-80'
                alt={img.thumbAlt || ''}
              />
            </div>
          );
        })}
      </div>
      {isOpen && (
        <div className=''>
          <Lightbox
            mainSrc={images[index]}
            nextSrc={images[(index + 1) % images.length]}
            prevSrc={images[(index + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setIndex(prevIndex)}
            onMoveNextRequest={() => setIndex(nextIndex)}
            // imageTitle={pics[index].title || ''}
            imageCaption={pics[index].title || ''}
            imagePadding={55}
          />
        </div>
      )}
    </div>
  );
};

export default GalleryComponent;

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CollectionGallery from './CollectionGallery';
import GalleryComponent from './FullGalleryComponent';

const FullGallery = () => {
  const data = useStaticQuery(graphql`
    query AllPics {
      allContentfulZebiCollection(sort: { fields: year, order: DESC }) {
        nodes {
          title
          pictures {
            id
            gatsbyImageData
            title
            localFile {
              childImageSharp {
                thumb: gatsbyImageData(
                  width: 240
                  height: 240
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 85
                )
                full: gatsbyImageData(
                  layout: FULL_WIDTH
                  formats: [AUTO, WEBP]
                  quality: 85
                )
              }
            }
          }
        }
      }
    }
  `);

  let allnodes = data.allContentfulZebiCollection.nodes;
  let allpics = allnodes.flatMap((elem) => elem.pictures);

  return (
    <section className='pt-20 lg:pt-32 px-5 lg:px-32'>
      <div className='pb-3 lg:pb-0'>
        <GalleryComponent pics={allpics} />
      </div>
    </section>
  );
};

export default FullGallery;

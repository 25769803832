import React from 'react';
import { AnimatePresence, motion, motionValue } from 'framer-motion';
import { Link } from 'gatsby';
import Collections from './Collections';
import Layout from '../layouts/Layout';
import SiteMetadata from './SiteMetadata';
import FullGallery from './FullGallery';
import About from '../components/About';

function Sidebar({ sideBar = false, setSideBar = () => {} }) {
  return (
    <AnimatePresence>
      {sideBar && (
        <>
          <motion.div
            initial={{ x: '100%' }}
            animate={{
              x: 0,
            }}
            exit={{
              x: '100%',
            }}
            transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
            className='fixed top-8 lg:top-16 right-0 w-full h-screen px-5  max-w-md  lg:max-w-xs '
          >
            <button
              onClick={() => setSideBar((sideBar) => !sideBar)}
              className='text-zebiBlue text-3xl font-bold flex rounded-full h-8 w-8 pt-4 border-zebiBlue mb-6 ml-auto lg:-ml-2'
            >
              &times;
            </button>
            {/* <h2 className="text-4xl capitalize leading-loose">about</h2> */}
            <div className='text-sm lg:text-lg tracking-tight leading-tight px-4'>
              <About />
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: 'spring', bounce: 0, duration: 0.2 }}
            onClick={() => setSideBar((sideBar) => !sideBar)}
            className='bg-transparent px-5  h-full w-full flex items-center justify-center top-0 left-0'
          />
        </>
      )}
    </AnimatePresence>
  );
}

function Header({ sideBar, setSideBar }) {
  return (
    <motion.div transition={{ type: 'spring', bounce: 0, duration: 0.4 }}>
      <div className='flex border-b border-zebiBlue h-8 lg:h-16'>
        <div className='w-3/5 lg:w-2/5 flex items-center'>
          <Link to='/'>
            <p className='text-base lg:text-3xl hover:text-blue-500 transition duration-150 ease-in-out'>
              sebastian díaz de león
            </p>
          </Link>
        </div>
        <button className='flex items-center w-1/8 -ml-3 mt-0.5'>
          <Link to='/gallery'>
            <div className='grid grid-cols-3 gap-0.5'>
              <div className='h-1 w-1 lg:h-2 lg:w-2 bg-zebiBlue'></div>
              <div className='h-1 w-1 lg:h-2 lg:w-2 bg-zebiBlue'></div>
              <div className='h-1 w-1 lg:h-2 lg:w-2 bg-zebiBlue'></div>
              <div className='h-1 w-1 lg:h-2 lg:w-2 bg-zebiBlue'></div>
              <div className='h-1 w-1 lg:h-2 lg:w-2 bg-zebiBlue'></div>
              <div className='h-1 w-1 lg:h-2 lg:w-2 bg-zebiBlue'></div>
            </div>
          </Link>
        </button>
        <div className='flex items-center ml-auto space-x-2'>
          <button
            className='leading-none italic text-sm lg:text-xl font-medium  hover:text-blue-500 transition duration-150 ease-in-out'
            onClick={() => setSideBar((sideBar) => !sideBar)}
          >
            {sideBar ? 'contact + about' : 'contact + about'}
          </button>
          {/* <button
          className="leading-none italic text-sm lg:text-xl font-medium hover:text-blue-500 transition duration-150 ease-in-out"
          onClick={() => setSideBar((sideBar) => !sideBar)}
        >
          {sideBar ? "about" : "about"}
        </button> */}
        </div>
      </div>
    </motion.div>
  );
}

function GalleryWrapper({ sideBar }) {
  return (
    <div className={sideBar ? 'pt-64 lg:pt-100' : 'pt-0'}>
      <FullGallery />
    </div>
  );
}

export default function App() {
  const [sideBar, setSideBar] = React.useState(false);
  return (
    <section>
      <div className='w-full pt-3 px-5 lg:px-10  text-zebiBlue z-50 fixed'>
        <Header {...{ sideBar, setSideBar }} />
        <Sidebar {...{ sideBar, setSideBar }} />
      </div>
      <GalleryWrapper {...{ sideBar, setSideBar }} />
    </section>
  );
}

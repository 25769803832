import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import GalleryPage from "../components/GalleryPage"

const AllPics = () => {
  return (
    <Layout>
      <SiteMetadata title="Gallery" description="Gallery | Sebastian Díaz de León" />
      <GalleryPage />
    </Layout>
  )
}

export default AllPics


